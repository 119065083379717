@use "sass:meta" as ---70mow6mkfwa;.main-v2-other {
  @include breakpoint(medium) {
    margin-top: 3rem;
  }

  .photo-gallery {
    position: relative;
    overflow: hidden;

    &:hover {
      .photo-gallery_desc {
        width: 0;
        padding: 0;

        .tizer {
          &::before {
            display: none;
          }

          span {
            display: none;
          }
        }
      }

      img {
        scale: 1.1;
      }
    }

    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: scale 2s;
    }

    &_desc {
      position: absolute;
      bottom: 0;
      background-color: map-get($foundation-palette, primary);
      width: 100%;
      height: 80px;
      padding: 1rem;
      transition: all .5s;

      .tizer {
        display: flex;
        align-items: center;
        color: $white;
        font-weight: $global-weight-bold;
        font-size: rem-calc(20);
        width: 80%;
        line-height: 1.2;

        @include breakpoint(small only) {
          font-size: rem-calc(15);
        }

        &::before {
          content: '';
          display: block;
          width: 60px;
          height: 50px;
          background-image: url('svg/photo-gallery.svg');
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 20px;
          flex-shrink: 0;

          @include breakpoint(medium down) {
            background-size: 70%;
          }
        }

        span {
          margin: 0;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .other-text {
    background-color: $alice-blue;
    padding: 20px;

    h3 {
      font-size: rem-calc(24);
      margin-bottom: 1rem;
    }

    p {
      font-size: rem-calc(14);

      a {
        color: map-get($foundation-palette, primary);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .electop {
    margin-top: 1rem;

    @include breakpoint(small only) {
      margin-top: 2rem;
    }

    &_header {
      display: flex;
      background-color: $alice-blue;
      color: $black;
      font-size: rem-calc(12);
      padding: 8px 12px 8px 20px;
      margin-bottom: .8rem;

      .title {
        width: 70%;

        @include breakpoint(small only) {
          width: 56%;
        }
      }

      .views,
      .visitors {
        width: 15%;
        text-align: end;

        @include breakpoint(small only) {
          width: 22%;
        }
      }
    }

    &_item {
      display: flex;
      padding: 8px 12px 8px 0;

      .num {
        width: 5%;
        font-size: rem-calc(14);
        text-align: right;
        padding-right: .5rem;
      }

      .item {
        width: 65%;

        @include breakpoint(small only) {
          width: 51%;
        }

        .name {
          font-size: rem-calc(14);
          margin: 0;
          -webkit-line-clamp: 1;
        }

        .site {
          font-size: rem-calc(12);

          a {
            color: $dark-gray;

            &:hover {
              color: $service;
            }
          }
        }
      }

      .views,
      .visitors {
        width: 15%;
        text-align: end;

        @include breakpoint(small only) {
          width: 22%;
        }

        .count {
          font-size: rem-calc(14);
        }

        .change {
          font-size: rem-calc(12);

          &.up {
            color: map-get($foundation-palette, success);
          }

          &.down {
            color: map-get($foundation-palette, alert);
          }
        }
      }
    }

    &_add {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;

      .upd {
        font-weight: $global-weight-bold;
        font-size: rem-calc(13);
      }

      a {
        margin: 0;
        width: 200px;
      }
    }
  }

  .banners {
    margin-top: 2rem;

    @include breakpoint(medium down) {
      #bs59 {
        display: none;
      }
    }

    .cell-banner {
      display: flex;
      justify-content: center;
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3776");